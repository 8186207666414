@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

#box {
    

    
    color: white;
    margin: auto;
    position: relative;
    
    float: right;
 
    
}



/* https://codepen.io/mike-schultz/pen/NgQvGO credit for animated border example */
.gradient-border {
  --borderWidth: 5px;
  background: #181818;
  position: relative;
  border-radius: var(--borderWidth);
  float: right;
  
  
}
.gradient-border:after {
  content: '';
  position: absolute;
  top: calc(-1 * var(--borderWidth));
  left: calc(-1 * var(--borderWidth));
  height: calc(100% + var(--borderWidth) * 2);
  width: calc(100% + var(--borderWidth) * 2);
  background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
  border-radius: calc(2 * var(--borderWidth));
  z-index: -1;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

.right:hover{
  color: #42f57e !important;
}




@keyframes animatedgradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


  .right {
    position: relative;
    color: white;
    font-family: "DejaVu Sans Mono", monospace;
    font-weight: bold;
    transition: 0.3s;
    	
  }

  


  
  #image {
      position: relative;
      
      border: 2px solid #1DB954;
      height: 200px;
      width: 200px;
      border-radius: 5px;
      
  }


  #nexbox{
    border:2px solid #f00;
    
  }


  .spotify-img{
    float: left;
    padding: 5px;
    height: 40px;
    width: 40px;
  }

  .hold{
    color: white;
  }

  .tooltext{
    font-family: 'Montserrat', sans-serif;
    color: white;
    font-weight: 100;
  }

  .youtube{
    font-size: 32px;
  }


  .detail{
    font-size: 24px;
    margin-bottom: 50px;
    
  }

  .spotify-display{
    margin-bottom: 50px;
    padding-bottom: 50px;
  }
  

#white{
  color: white;
}