@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&family=Tajawal:wght@500&display=swap');



.navbar-brand{
    transition: 0.3s;
}

.nav-link{
    transition: 0.3s;
}

a:hover{
    color: lightblue !important;
}

body{
    font-family: 'Tajawal', sans-serif !important;
    align-items: center;
    background-color: black;
}

html{
    scroll-behavior: smooth;
}

.welcome{
    margin: auto;
    justify-content: center;
    text-align: center;
    text-decoration-color: red;
    align-items: center;
    
}

#white-text{
    color: white;
}

.banner-text{
    color: black;
    font-family: 'Trebuchet MS', sans-serif;
    font-size: 48px;
    
    font-stretch: expanded;
    font-style: oblique;
}

.photo{
    text-align: center;
    
}

.format{
    position: relative;
    top: 60px;
    border-radius: 50%;
    height: 250px;
    width: 250px;
}

.new-text{
    position: relative;
    top: 90px;
    font-family: "Noto Sans", sans-serif;
}

.listening{
    position: relative;
    top: 200px;
    text-align: center;
    font-family: 'Verdana', sans-serif;
    font-size: 32px;
    font-style: italic;
    font-weight: lighter;
}

#contact-icon{
    font-size: 60px;
    transition: 0.3s;
}

#contact-banner{
    color:white;
    font-size: 48px;
    font-weight: bold;
}

.social{
    color: white;
}



.fa-instagram:hover{
    color: #e95950;
}

.fa-facebook-square:hover{
    color: #3b5998;
}

.fa-github:hover{
    color: lightblue;
}


.fa-linkedin-square:hover{
    color: #2867B2;
}

.jumbotron{
    background-color: transparent !important;
}

.enter{
    font-size: 32px;
    
}

.jumbotron{
    justify-content: center;
    text-align: center;
}

.image_hold{
    text-align: center;
}

.alex_image{
    height: 500px;
    width: 400px;
}


.about{
    margin-top: 150px;
    margin-left: 50px;
    padding-bottom: 50px;
    font-size: 46px;
}


.about-detail{
    font-size: 24px;
    
}

.anteater{
    height: 200px;
    width: 300px;
}

.make_c{
    justify-content: center;
    text-align: center;
}

.uci-part{
    padding-bottom: 50px;
    margin-bottom: 50px;
}

.hobbies{
    justify-content: center;
    text-align: center;
    padding-bottom: 50px;
}

.ball{
    margin-bottom: 50px;
    padding-bottom: 50px;
}